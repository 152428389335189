<template>

    

    <div style="padding:20px;">
        
        <v-card class="" elevation="" max-width="" >
            <v-card-text>

                <div class="linhaFormulario">



                    <div class="celularCampoForm">
                        <v-select color v-model="idprojeto" :items="listaProjetos" label="Projetos"  item-text="titulo" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field label="ID" placeholder="ID" v-model="idsolicitacaofiltro" name="texto"></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field label="Texto" placeholder="Parte do Texto da Atividade" v-model="texto" name="texto"></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="idusuariosolicitante" :items="listaUsuariosSolicitantes" label="Solicitante"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="tipofiltro" :items="tiposFiltros" label="Tipo"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="idusuario" :items="listaUsuarios" label="Destino"  item-text="nome" item-value="id"></v-select>
                    </div>



                    <div class="celularCampoForm">
                        <v-select color v-model="concluido" :items="listaStatusConcluido" label="Concluidas"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="statussolicitacaoselecionado" :items="listaStatusSolicitacao" label="Status Solicitação"  item-text="nome" item-value="id"></v-select>
                    </div>

                    

                </div>
                
 
                <div class="linhaFormulario" style="display:flex;flex-direction:row;justify-content:flex-end;">
                   
                    <v-container class="">
                       <v-row>
                         <v-col cols="12" md="8">
                                
                         </v-col>
                         <v-col cols="12" md="4">
                            <div style="">
                                <div style="display:flex;flex-direction:row;justify-content:space-between;padding:0px!important;padding-right:15px;padding-bottom:10px;">
                                    <div class="celularCampoForm" style="margin-top:10px;width:40%;padding:0px!important;">
                                            <v-btn block elevation="" large color="info" @click="listaSolicitacoesProjetos()" >Buscar</v-btn>
                                    </div>
                                    <div class="celularCampoForm" style="margin-top:10px;width:40%;padding:0px!important;">
                                            <v-btn block elevation="" large color="warning" @click="limpar()" >Limpar</v-btn>
                                    </div>
                                </div>
                                <JsonCSV :data="atividadesProjetos" v-if="atividadesProjetos.length > 0" :name="nome_relatorio">
                                    <div style="background-color:#2b81d6;width:450px;height:40px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;">
                                        Download Resultado 
                                    </div>
                                </JsonCSV>
                            </div>
                         </v-col>
                       </v-row>
                     </v-container>


                    
       
                </div>
                
            </v-card-text>
               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="display:flex;justify-content:flex-end;padding:10px;">
                    <v-btn large elevation="" color="info" @click="novaAtividade()">Nova Solicitação</v-btn>
                </div>
                    
                    <template>
                        <v-data-table :headers="headers" :items="atividadesProjetos" item-key="idsolicitacao" :items-per-page="2300" class="elevation-1" >

                            <template v-slot:[`item.nomeatividade`]="{ item }">
                                <div style="display:flex;flex-direction:row;width:100%;">
                                    <div style="">
                                       <v-icon v-if="item.fl_concluida" color="success" :title="`Concluida em ${item.dt_concluida}`">mdi-checkbox-marked</v-icon>
                                    </div>
                                    <div style="">
                                       <v-icon v-if="!item.fl_concluida && item.nomeusuario" color="info" title="Atribuída mas não concluida" >mdi-account-hard-hat</v-icon>
                                    </div>
                                    <div style="">
                                       <v-icon v-if="!item.nomeusuario" color="error" title="Não Atribuida" >mdi-cancel</v-icon>
                                    </div>
                                    <div style="display:flex;flex-direction:column;maxWidth: 100%;overflow: hidden;text-overflow: ellipsis;">
                                        <div style="font-weight:bold;">{{item.nomeatividade}}<span v-if="item.nomeusuario" style="color:blue">&nbsp;{{item.nomecliente}}</span></div>
                                        <!-- <div>{{item.descricao}}</div> -->
                                        <div v-html="item.descricao" ></div>
                                       
                                    </div>
                                </div>
                            </template> 

                            <template v-slot:[`item.testado`]="{ item }">
                                <div style="">
                                    <div style="">
                                       <v-icon v-if="item.testado" color="success" :title="``">mdi-check</v-icon>
                                    </div>
                                </div>
                            </template> 

                            <template v-slot:[`item.fl_negada`]="{ item }">
                                <b style="color:red;">{{item.fl_negada === true? 'Negada':''}}</b>
                            </template> 

                            <template v-slot:[`item.fl_concluida`]="{ item }">
                                <b style="color:green;">{{item.fl_aceite === true? 'Acatada':''}}</b>
                            </template> 

                            <template v-slot:[`item.nomeurgencia`]="{ item }">
                                <div :style="`color:${item.nomeurgencia == 'Baixa'? 'black;':
                                                        item.nomeurgencia == 'Media'? 'blue':'red'}`">
                                    {{item.nomeurgencia}}
                                </div>
                            </template> 

                            <template v-slot:[`item.producao`]="{ item }">
                                <div style="">
                                    <div style="">
                                       <v-icon v-if="item.producao" color="success" :title="``">mdi-check</v-icon>
                                    </div>
                                </div>
                            </template> 

                            

                            <template v-slot:[`item.idatividade`]="{ item }">
                                <v-btn small elevation="" class="ml-2" @click="setExibirModalConfiguracoes(item)" color="info">
                                    <v-icon color="">mdi-cog-outline</v-icon>
                                </v-btn>
                            </template> 

                            
                            
                        
                        </v-data-table>
                    </template>


            </v-card-text>
               
        </v-card>







            <v-dialog v-model="exibirModalConfiguracoes" width="80vw" style="max-height:90vh;height:90vw;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="background-color:white;width:60%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">
                                <div>
                                    <v-container class="pa-8">
                                        <v-row>

                                            <v-col cols="12" md="12" v-if="atividadeselecionada">
                                                    <!-- {{atividadeselecionada}} -->
                                                    <v-container class="">

                                                        <v-row style="padding-top:0px!important;padding-bottom:0px!important;">
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Projeto</v-col>
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Responsavel</v-col>
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Dt. Est. Entrega</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px!important;padding-bottom:0px!important;">
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >{{atividadeselecionada.nomeprojeto}}</v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <a v-if="!exibirAlterarResponsavel" @click="setExibirAlterarResponsavel()" style="font-weight:bold;">{{atividadeselecionada.nomeusuario}}</a>
                                                                <v-select v-if="exibirAlterarResponsavel" color v-model="idResponsavelTroca" :items="listaUsuarios"  item-text="nome" item-value="id" id="alterarResp" name="alterarResp" return-object
                                                                    @change="setExibirAlterarResponsavel()" hide-details style="height:10px;padding-top:0px;margin-top:0px;"></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <a v-if="!exibirAlterarDataEstimativa" @click="setExibirAlterarDataEstimativa()" style="font-weight:bold;">{{atividadeselecionada.dt_estimativa}}</a>
                                                                <v-text-field id="alterarDtEst" name="alterarDtEst" autofocus v-if="exibirAlterarDataEstimativa" type="date" @blur="setExibirAlterarDataEstimativa()"  @change="setExibirAlterarDataEstimativa()" v-model="atividadeselecionada.dt_estimativa_ing" style="height:10px;padding-top:0px;margin-top:0px;" hide-details></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;margin-top:30px;">
                                                            <v-col  cols="12" md="2" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >Atividade</v-col>
                                                        </v-row>
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >{{atividadeselecionada.nomeatividade}}</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;margin-top:40px;">
                                                            <v-col  cols="12" md="2" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >Descrição</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >{{atividadeselecionada.descricao}}</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;margin-top:40px;">
                                                            <v-col  cols="12" md="12" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >Status Da Solicitação</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="12" style="padding-top:0px;padding-bottom:0px;" >
                                                                <h4 :style="atividadeselecionada.fl_negada === true? 'color:red;':(atividadeselecionada.fl_aceite === true? 'color:green;':'')">
                                                                    {{atividadeselecionada.fl_negada === true? 'Negada':
                                                                    atividadeselecionada.fl_aceite === true? 'Acatada':'' }}</h4>
                                                            </v-col>
                                                            <v-col cols="12" md="12" style="padding-top:0px;padding-bottom:0px;" >
                                                                {{atividadeselecionada.fl_negada === true? atividadeselecionada.motivo_negativa:
                                                                    atividadeselecionada.fl_aceite === true? atividadeselecionada.motivo_aceite:'' }}
                                                            </v-col>
                                                        </v-row>
                        
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;margin-top:30px;">
                                                            <v-col  cols="12" md="12" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >{{textoAuxiliar1}}</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >{{atividadeselecionada.dadoAuxiliar1}}</v-col>
                                                        </v-row>
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;margin-top:30px;">
                                                            <v-col  cols="12" md="12" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >{{textoAuxiliar2}}</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >{{atividadeselecionada.dadoAuxiliar2}}</v-col>
                                                        </v-row>
                                                
                                                        
                                                    </v-container>

                                            </v-col>

                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                <div>
                                    <v-container  v-if="atividadeselecionada && atividadeselecionada.fl_negada !== true && atividadeselecionada.fl_aceite !== true " class="pa-8" style="padding-top:1px!important;">
                                        <v-row v-if="atividadeselecionada.idresponsavel == idusuariologado">

                                            <v-col cols="12" md="12">Observacao
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="movimento.descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">

                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="error" @click="negar(atividadeselecionada)"  hide-details>{{textoBotaoNegar}}</v-btn >
                                                        </v-col>
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            
                                                        </v-col>
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <!-- <v-btn color="info" @click="finalizarMovimenCtar(4)" hide-details >Atendimento Realizado</v-btn > -->
                                                            <v-btn color="info" @click="atender(atividadeselecionada)" hide-details >{{textoBotaoAtender}}</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                   

                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                            <div style="background-color:#efefef;width:40%;display:flex;flex-direction:column;padding:10px;max-height:80vh;height:80vw;overflow:hidden;padding-bottom:20px!important;">

                                <v-container class="pa-2" style="max-height:80vh;padding-bottom:20px!important;">
                                    <v-card class="" style="margin-bottom:10px;" elevation="" max-width="" v-for="item in movimentos" :key="item.id" :dot-color="item.color" >
                                        <v-card-title style="font-size:16px;">{{item.desctipomovimento}}</v-card-title>
                                        <v-card-text style="font-size:16px;">
                                            <div><b>Descrição:</b> {{item.descricao}}</div>
                                            <div><b>Horas Gastas:</b> {{item.tempo_gasto}}</div>
                                        </v-card-text>
                                        <v-card-text style="display:flex;flex-direction:column;justify-content:flex-end;align-items:flex-end;font-weight:600;color:#817373;">
                                            <div>{{item.datamovimento}} as {{item.horamovimento}}</div> 
                                            <div>{{item.nomeusuario}}</div> 
                                        </v-card-text>
                                    
                                    </v-card>
                                </v-container>


                            </div>

                    </div>

            </v-dialog>


                
                <v-dialog v-model="exibirModalCriarAtividade" width="80vw" max-width="1000px">
                    <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
                        <div>
                            <v-container class="pa-8">

                                <v-row>
                                        <v-col cols="12" md="12">
                                                <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="atividadeCriar.titulo" outlined return-object hide-details dense>
                                                </v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="12">
                                                <!-- <v-textarea label="Descrição"  v-model="atividadeCriar.descricao" outlined return-object hide-details dense >
                                                </v-textarea>  -->
                                                <label for="descricao">Descrição</label>
                                                <vue-editor id="descricao" v-model="atividadeCriar.descricao"></vue-editor>
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                label="Selecionar Projeto"
                                                :items="selectPrioridade"
                                                v-model="atividadeCriar.prioridade"
                                                item-text="titulo" 
                                                item-value="id"
                                            ></v-autocomplete>

                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                label="Tipo"
                                                :items="tipos"
                                                item-text="nome"
                                                item-value="id"
                                                v-model="atividadeCriar.tipo" 
                                                return-object
                                            ></v-autocomplete>

                                        </v-col>


                                        <v-col class="d-flex" cols="12" sm="3" >
                                            <v-autocomplete
                                                label="Destinatario"
                                                :items="listadeusuarios"
                                                v-model="usuarioAtribuirAtividade"
                                                item-text="nome" 
                                                item-value="id"
                                                @change="buscarAtribuidasPendentes({idusuario:usuario.id})" 
                                                outlined return-object hide-details dense
                                            ></v-autocomplete>
 
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >

                                            <v-autocomplete
                                                label="Cliente"
                                                :items="clientes"
                                                v-model="atividadeCriar.idcliente"
                                                item-text="nome" 
                                                item-value="id"
                                                @change="buscarAtribuidasPendentes({idusuario:usuario.id})" 
                                                outlined return-object hide-details dense
                                            ></v-autocomplete>
 

                                           
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="3" >

                                            <v-autocomplete
                                                label="Prioridade Estimada"
                                                :items="listaurgencia"
                                                v-model="atividadeCriar.urgencia"
                                                item-text="nome" 
                                                item-value="id"
                                                @change="buscarAtribuidasPendentes({idusuario:usuario.id})" 
                                                outlined return-object hide-details dense
                                            ></v-autocomplete>
 

                                           
                                        </v-col>



                                            <v-col class="d-flex" cols="12" sm="3" v-if="atividadeCriar?.tipo?.id == 5">
                                                <v-text-field type="number" label="Id Atividade" v-model="atividadeCriar.idatividaderelacionada" outlined return-object hide-details dense>
                                                </v-text-field>
                                            </v-col>

                                            <v-col class="d-flex" cols="12" sm="3" v-if="atividadeCriar?.tipo?.id == 6"  >
                                                <v-text-field type="date" label="Dia da Reunião" v-model="atividadeCriar.dt_entrega" outlined return-object hide-details dense>
                                                    </v-text-field>
                                            </v-col>

                                            <v-col class="d-flex" cols="12" sm="3" v-if="atividadeCriar?.tipo?.id == 6">
                                                <v-text-field type="time" label="Hora da Reunião" v-model="atividadeCriar.hora_agenda" outlined return-object hide-details dense>
                                                    </v-text-field>
                                            </v-col>

                                            
                            


                                        <v-col cols="12" md="12">
                                            
                                            <v-row>
                                                    <v-col>
                                                        <v-btn elevation="" @click="() => registrarSolicitacao()" color="info" block>Registrar</v-btn> 
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn elevation="" @click="() => exibirModalCriarAtividade=false" color="error" block> Cancelar</v-btn> 
                                                    </v-col>
                                            </v-row>

                                        </v-col>

                                </v-row>
                              

                            </v-container>
                        </div>
                        
                    </div>
                </v-dialog>

    </div>

    
</template>

<script>


// import sistemas from '@/services/sistema.service.js'
import usuarios from '@/services/usuario.service.js'
// import projetos from '@/services/prioridades.service.js'
import JsonCSV from 'vue-json-csv'
// import Prioridades from '@/services/prioridades.service.js';
import Clientes from '@/services/cliente.service.js'
import AtividadeServico from '@/services/atividade.service.js'
import SolicitacaoServico from '@/services/solicitacao.service.js'
import {alertar} from '@/services/notificacao.service.js';
import UsuarioServico from '@/services/usuario.service.js';
import { VueEditor } from 'vue2-editor'


import dayjs from 'dayjs'

export default {
    name:'Atividades',
    components: {
        JsonCSV,
        VueEditor
    },
    data(){
        return{
            listaurgencia:[
                {id:1,nome:'Baixa'},
                {id:2,nome:'Media'},
                {id:3,nome:'Alta'},
            ],
            listaStatusSolicitacao:[
                {id:0,nome:"Todos"},
                {id:1,nome:"Acatadas"},
                {id:2,nome:"Negadas"},
                {id:3,nome:"Pendentes"},
            ],
            statussolicitacaoselecionado:3,
            usuarioAtribuirAtividade:{},
            textoBotaoAtender:'',
            textoBotaoNegar:'',
            idusuariosolicitante:null,
            idsolicitacaofiltro:null,
            listaUsuariosSolicitantes:[],
            idResponsavelTroca:null,
            atividades_testadas:false,
            atividades_producao:false,
            items: [
                { id: 1, color: 'info', icon: 'mdi-information', },
                { id: 2, color: 'error', icon: 'mdi-alert-circle', },
                { id: 3, color: 'error', icon: 'mdi-alert-circle', },
                { id: 4, color: 'error', icon: 'mdi-alert-circle', },
                { id: 5, color: 'error', icon: 'mdi-alert-circle', },
                { id: 6, color: 'error', icon: 'mdi-alert-circle', },
                { id: 7, color: 'error', icon: 'mdi-alert-circle', },
                { id: 8, color: 'error', icon: 'mdi-alert-circle', },
                { id: 9, color: 'error', icon: 'mdi-alert-circle', },
            ],
            movimentos:[],
            novaObservacao:null,
            exibirModalConfiguracoes:false,
            nome_relatorio:'relatorio.csv',
            dt_concluida_inicial:null,
            dt_concluida_final:null,
            dt_estimativa_inicial:null,
            dt_estimativa_final:null,
            idprojeto:null,
            listaProjetos:[
                {id:1,titulo:'Todos'}
            ],
            idstatusprojeto:{id:3,titulo:'Não Concluidos'},
            listaStatusProjetos:[
                {id:1,titulo:'Todos'},
                {id:3,titulo:'Concluidos'},
                {id:2,titulo:'Não Concluidos'},
            ],
            idusuario:null,
            idusuariologado:null,
            listaUsuarios:[
                {id:1,nome:'Todos'}
            ],
            atribuida:2,
            listaAtribuidas:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Atribuidas'},
                {id:3,nome:'Não Atribuidas'},
            ],
            concluido:3,
            listaStatusConcluido:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Concluído'},
                {id:3,nome:'Não Concluído'},
            ],
            headers: [

                        { text: 'Projeto', align: 'start',sortable: true,value: 'nomeprojeto',},
                        { text: 'Solicitante', value: 'nomesolicitante',sortable: true, },
                        { text: 'Destino', value: 'nomeusuario',width:"",sortable: true, },
                        { text: 'Tipo', value: 'tiposolicitacao',width:"",sortable: true, },
                        { text: 'ID', align: 'start',sortable: true,value: 'idsolicitacao',},
                        { text: 'Urgencia', align: 'start',sortable: true,value: 'nomeurgencia',},
                        { text: 'Solicitacao', value: 'nomeatividade',maxWidth:"50%",sortable: true, },
                        { text: 'Dt Criação', value: 'dt_criacao',sortable: true, },
                        { text: 'Concluida', value: 'dt_concluida',sortable: true, },
                        { text: 'Negativa', value: 'fl_negada',sortable: true, },
                        { text: 'Aceite', value: 'fl_concluida',sortable: true, },
                        // { text: 'Testado', value: 'testado',sortable: true, },
                        // { text: 'Producao', value: 'producao',sortable: true, },
                        // { text: 'Hrs Est.', value: 'horas_estimadas',sortable: true, },
                        // { text: 'Hrs Trab.', value: 'qtdehorastrabalhadas',sortable: true, },
                        { text: 'Ação', value: 'idatividade',sortable: true, },
                    ],
            atividadesProjetos:[],
            atividadeselecionada:null,
            texto:null,
            exibirModalCriarAtividade:false,
            atividadeCriar: { id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0,urgencia:null },
            movimento:{idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null},
            selectPrioridade:[],
            tipos: [],
            tiposFiltros: [],
            tipofiltro:{id:0,nome:'Todos'},
            filtroTipos: [],
            // filtroTipos: ['Analise de Possível Bug', 'Solicitação a Diretoria', 'Liberação de Ambiente', 'Nova Implantação',"Solicitação de Testes"],
            clientes:[],
            clienteSelecionado:0,
            listadeusuarios:[],
            usuario:null,
            exibirAlterarResponsavel:false,
            exibirAlterarDataEstimativa:false,
            filtroRapidoSelecionado:{},
            ativiadeConcluida: false,
            filtroProjeto: null,
            searchQueryProjetos: '',
            searchQueryTipos: '',
            textoAuxiliar1:'',
            textoAuxiliar2:'',
            // tiposSolicitacoes:[],
        }
    },
    watch: {
        
    },
    methods:{

            filtrarProjetos() {
                const filtro = this.selectPrioridade.filter(option => {
                return option.titulo.toLowerCase().includes(this.searchQueryProjetos.toLowerCase())
            })
                this.filtroProjeto = filtro
                this.atividadeCriar.prioridade = filtro[0];
            },

            filtrarTipos() {
                const filtro = this.tipos.filter(option => {
                return option.toLowerCase().includes(this.searchQueryTipos.toLowerCase())
            })
                this.filtroTipos = filtro
                this.atividadeCriar.tipo = filtro[0];
            },

            async setMarcadoComoTeste(){

                let obj = {}
                let objAux = {
                                idatividade: this.atividadeselecionada.idatividade,
                                datahoramovimento: dayjs().format(),
                                idusuario: localStorage.getItem('idusuario'),
                            }

                if(this.atividadeselecionada.testado === true){
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:4,testado:true }
                    objAux.descricao = 'Atividade marcada como Testada'
                    objAux.tipomovimento = 7
                    objAux.idusuario = localStorage.getItem('idusuario')
                }else{
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:1,testado:false }
                    objAux.descricao = 'Atividade retirada do Status de Testada'
                    objAux.tipomovimento = 9
                    objAux.idusuario = localStorage.getItem('idusuario')
                }

                await AtividadeServico.atualizar(obj).then(async () => { 
                    
                                AtividadeServico.registrarMovimento(objAux).then(()=> this.buscarMovimentos())

                        }).catch(error=>{
                            alert('Não foi Possível Registrar!1')
                            console.log(error)
                        })
            },
            async setMarcadoEmProducao(){

                let obj = {}
                let objAux = {
                                idatividade: this.atividadeselecionada.idatividade,
                                datahoramovimento: dayjs().format(),
                                idusuario: localStorage.getItem('idusuario'),
                            }

                if(this.atividadeselecionada.producao === true){
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:5,producao:true }
                    objAux.descricao = 'Atividade marcada como Enviada para Produção'
                    objAux.tipomovimento = 8
                    objAux.idusuario = localStorage.getItem('idusuario')
                }else{
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:4,producao:false }
                    objAux.descricao = 'Atividade retirada da Enviada para Produção'
                    objAux.tipomovimento = 10
                    objAux.idusuario = localStorage.getItem('idusuario')
                }

                await AtividadeServico.atualizar(obj).then(async () => { 
                                AtividadeServico.registrarMovimento(objAux).then(()=> this.buscarMovimentos())
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!2')
                            console.log(error)
                        })
            },
            async setExibirAlterarDataEstimativa(){
                this.exibirAlterarDataEstimativa = !this.exibirAlterarDataEstimativa
                
                if(this.exibirAlterarDataEstimativa){
                    
                    document.getElementById('alterarDtEst').focus()
                    
                }else{
                   
                        this.atividadeselecionada.dt_estimativa = dayjs(this.atividadeselecionada.dt_estimativa_ing).format('YYYY-MM-DD')
                        this.atividadeselecionada.dt_entrega = dayjs(this.atividadeselecionada.dt_estimativa_ing).format('YYYY-MM-DD')
                        this.atividadeselecionada.id = this.atividadeselecionada.idatividade
                        await AtividadeServico.atualizar(this.atividadeselecionada).then(async () => { 
                            console.log("ok")
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!3')
                            console.log(error)
                        })

                }
            },
            async setExibirAlterarResponsavel(){
                this.exibirAlterarResponsavel = !this.exibirAlterarResponsavel
                
                if(this.exibirAlterarResponsavel){
                    document.getElementById('alterarResp').focus()
                }else{
                        alert(JSON.stringify(this.idResponsavelTroca,null,2))
                        this.atividadeselecionada.idresponsavel = this.idResponsavelTroca.id
                        this.atividadeselecionada.nomeresponsavel = this.idResponsavelTroca.nome
                        this.atividadeselecionada.id = this.atividadeselecionada.idatividade
                        await AtividadeServico.atualizar(this.atividadeselecionada).then(async () => { 
                            this.exibirModalConfiguracoes = false
                            this.listaSolicitacoesProjetos()
                            console.log("ok")
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!3')
                            console.log(error)
                        })

                }
            },
            async setarFiltro(dados){

                this.filtroRapidoSelecionado = dados

                if(dados.tipo == 'P'){
                    if(dados.dias !== null){
                        this.dt_estimativa_inicial  = dayjs().subtract(dados.dias,'days').format('YYYY-MM-DD')
                        this.dt_estimativa_final  = dayjs().format('YYYY-MM-DD')
                    }else{
                        this.dt_estimativa_inicial  = null
                        this.dt_estimativa_final    = null
                    }
                    
                    this.atribuida = 2
                    this.concluido = 3

                    //limpar
                    this.dt_concluida_inicial  = null
                    this.dt_concluida_final    = null
                }

                 if(dados.tipo == 'C'){
                    if(dados.dias !== null){
                        this.dt_concluida_inicial  = dayjs().subtract(dados.dias,'days').format('YYYY-MM-DD')
                        this.dt_concluida_final  = dayjs().format('YYYY-MM-DD')
                    }else{
                        this.dt_concluida_inicial  = null
                        this.dt_concluida_final    = null
                    }

                    this.atribuida = 2
                    this.concluido = 2


                    //limpar
                    this.dt_estimativa_inicial  = null
                    this.dt_estimativa_final    = null
                }
                
                // this.dt_concluida_final
            },
            async buscarMovimentos(){

                    await SolicitacaoServico.buscarMovimento({idsolicitacao:this.atividadeselecionada.idsolicitacao}).then(async (resp) => { 

                        let movimentos = resp.data
                        movimentos = movimentos.sort(function (a, b) {
                            if (a.datahoramovimento > b.datahoramovimento) {
                                return -1;
                            }
                            if (a.datahoramovimento < b.datahoramovimento) {
                                return 1;
                            }
                            return 0;
                        })
                        this.movimentos = movimentos

                    }).catch(error=>{
                        // alert('Não foi Possível Registrar!4')
                        console.log(error)
                    })

            },
            async registrarSolicitacao(){
        
                if(this.atividadeCriar.titulo){

                    //se for uma do tipo teste, é necessario informar o campo id atividade
                    if (this.atividadeCriar.tipo.id == 5 && !this.atividadeCriar.idatividaderelacionada) {
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo com o id da Atividade que deseja solicitar testes!')
                        return
                    }
   
                    //se for uma do tipo meet, é necessario informar o campo dia e hora da reunião
                    if (this.atividadeCriar.tipo.id == 6 && (!this.atividadeCriar.dt_entrega || !this.atividadeCriar.hora_agenda)) {
                        
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo com o dia e a hora da reunião!')
                        return
                    }

                    

                    this.atividadeCriar.idusuario = localStorage.getItem('idusuario');
                    this.atividadeCriar.idresponsavel = this.usuarioAtribuirAtividade.id

                    

                    if(!this.atividadeCriar.dt_entrega){
                        this.atividadeCriar.dt_entrega = dayjs().format('YYYY-MM-DD')
                    }

                    delete this.atividadeCriar.ultimaobs;
                    console.log(this.atividadeCriar);
                    if (this.ativiadeConcluida && !this.atividadeCriar.horas_estimadas) {
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo de horas estimadas!')
                        return
                    }

                    if (this.ativiadeConcluida && !this.atividadeCriar.descricao) {
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo de descrição!')
                        return
                    }

                    

                    this.atividadeCriar.tipo = this.atividadeCriar.tipo.id
                    this.atividadeCriar.idcliente = this.atividadeCriar.idcliente.id
                    this.atividadeCriar.urgencia = this.atividadeCriar.urgencia.id
                    console.log('this.atividadeCriar ',this.atividadeCriar)
                    await SolicitacaoServico.criar(this.atividadeCriar).then(async (resp) => { 
                        console.log("registrarSolicitacao resp ",resp)
                        alertar('success', '=)', 'Atividade registrada com sucesso!')

                        this.exibirModalCriarAtividade=false 
                        this.atividadeCriar={ id:null, titulo:null, descricao:null, idimplantacao:null, dt_entrega: null, idusuario: null,urgencia:null }
                        await this.listaSolicitacoesProjetos()

                        
                    }).catch(error=>{
                        alert('Não foi Possível Registrar!5 ')
                        alert(error)
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async registrarMovimento(tipo){
        
                if(this.movimento.descricao){

                    this.movimento.idusuario = localStorage.getItem('idusuario');
                    this.movimento.tipomovimento = tipo;
                    this.movimento.idatividade = this.atividadeselecionada.idatividade
                    this.movimento.idcliente = this.atividadeselecionada.idcliente
                    this.movimento.datahoramovimento = dayjs().format()

                    await AtividadeServico.registrarMovimento(this.movimento).then(async () => { 
                        this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                        this.buscarMovimentos()
                    }).catch(error=>{
                        alert('Não foi Possível Registrar!6')
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async marcarConcluida(atividade){

                    atividade.usuario ={id:localStorage.getItem('idusuario')}
                    atividade.id = atividade.idatividade

                    // alert(JSON.stringify(atividade,null,2))

                    await AtividadeServico.marcarConcluida(atividade).then(async () => { 
                        await this.buscarMovimentos()
                        await this.listaSolicitacoesProjetos()
                    }).catch(error=>{
                        alert('Não foi Possível Registrar! 7')
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })

            },
            async registrarAtividade(dados){

                if(dados.nomeatividade){
                    
                    dados.titulo = dados.nomeatividade
                    // dados.descricao = dados.descricao
                    dados.idusuario = localStorage.getItem('idusuario');
                    // dados.idresponsavel = 19
                    // dados.dt_entrega = this.idatividaderelacionada;

                    if(!dados.dt_entrega){
                        dados.dt_entrega = dayjs().format('YYYY-MM-DD')
                    }

                    delete dados.ultimaobs;
                    // console.log(dados);

                    delete dados.id
                    
                    dados.prioridade = dados.idprojeto
                    await AtividadeServico.criar(dados).then(async (resp) => { 
                        console.log("registrarAtividade resp ",resp)
                        alertar('success', '=)', 'Atividade registrada com sucesso!')
                        let obj = {
                                id:dados.idsolicitacao,
                                fl_aceite:true,
                                motivo_aceite:this.movimento.descricao,
                            }
                        await this.concluirAtividade(obj)     
                        await this.listaSolicitacoesProjetos()
                        this.exibirModalConfiguracoes = false

                        
                    }).catch(error=>{
                        alert('Não foi Possível Registrar! ')
                        alert(error)
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async criarAtividadeBug(dados){
                dados.tipo = 'Bug'
                this.registrarAtividade(dados)
            },
            async criarAtividadeNova(dados){
                this.registrarAtividade(dados)
            },
            async concluirAtividade(dados){
                console.log('dados >>> ',dados)
                await SolicitacaoServico.concluirSolicitacao(dados).then(async (resp) => { 
                        console.log("registrarAtividade resp ",resp)
                        alertar('success', '=)', 'Atividade registrada com sucesso!')
                    }).catch(error=>{
                        alert('Não foi Possível Registrar! ')
                        alert(error)
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
            },
            async negarSolicitacao(dados){
                 let obj = {
                        id:dados.idsolicitacao,
                        fl_negada:true,    
                        motivo_negativa:this.movimento.descricao,
                    }
                 await this.concluirAtividade(obj)                    
                 this.listaSolicitacoesProjetos()
                 this.exibirModalConfiguracoes = false
            },
            async negar(dados){
                //solicitação Analise de Possível Bug
                if(dados.tipo == 1){
                    dados.nomeatividade = 'Negado Apos Analise: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação Analise de Bug Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }


                    this.negarSolicitacao(dados)
                }

                //solicitação a diretoria
                if(dados.tipo == 2){
                    dados.nomeatividade = 'Negado pela Diretoria: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação a Diretoria Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.negarSolicitacao(dados)
                }

                //solicitação de nova implantação
                if(dados.tipo == 3){
                    dados.nomeatividade = 'Solicitação Negada: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                    console.log('sdadsa333 ',JSON.stringify(dados,null,2))
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Nova Implantação Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.negarSolicitacao(dados)
                }

                //Solicitação de Configuração apos a liberação do ambiente
                if(dados.tipo == 4){
                    dados.nomeatividade = 'Solicitação Negada: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Configuração de Ambiente Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }


                    this.negarSolicitacao(dados)
                }

                //Solicitação de realização de testes
                if(dados.tipo == 5){
                    dados.nomeatividade = 'Solicitação Negada: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Testes Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.negarSolicitacao(dados)
                }

                //Solicitação de Meet
                if(dados.tipo == 6){
                    dados.nomeatividade = 'Solicitação Negada: '+dados.nomeatividade
                    dados.motivoNegacao = this.movimento.descricao
                     //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Meet Negada: [${dados.idsolicitacao}] `,
                                html:dados.nomeatividade+'. <br><br> Motivo:'+dados.motivoNegacao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.negarSolicitacao(dados)
                }

            },
            async atender(dados){


                //solicitação Analise de Possível Bug
                if(dados.tipo == 1){
                    dados.nomeatividade = 'Gerado apos Analise: '+dados.nomeatividade
                    
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Analise de Bug [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeBug(dados)
                }

                //solicitação a diretoria
                if(dados.tipo == 2){
                    dados.tipo = 'Nova Funcionalidade'
                    dados.nomeatividade = 'Gerado pela Diretoria: '+dados.nomeatividade
                    
                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação a Diretoria [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }

                //solicitação de nova implantação
                if(dados.tipo == 3){
                    dados.tipo = 'Nova Implantação'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de nova implantação [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }
                    
                    this.criarAtividadeNova(dados)
                }

                //Solicitação de Configuração apos a liberação do ambiente
                if(dados.tipo == 4){
                    dados.tipo = 'Nova Configuração de Implantação'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Nova Configuração de Implantação [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }

                //Solicitação de realização de testes
                if(dados.tipo == 5){
                    dados.tipo = 'Testes'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Testes [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }

                //Solicitação de Meet
                if(dados.tipo == 6){
                    dados.tipo = 'Meet'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade
                    dados.dt_entrega = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Meet [${dados.idsolicitacao}] Confirmada`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }


                //Solicitação de realização de testes
                if(dados.tipo == 8){
                    dados.tipo = 'Testes'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Funcionalidade Nova [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }


                //Solicitação de realização de testes
                if(dados.tipo == 9){
                    dados.tipo = 'Testes'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Documentação [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }


                //Solicitação de realização de testes
                if(dados.tipo == 10){
                    dados.tipo = 'Testes'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Desativação [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }


                //Solicitação de realização de testes
                if(dados.tipo == 11){
                    dados.tipo = 'Testes'
                    dados.nomeatividade = 'Solicitação acatada: '+dados.nomeatividade

                    //Enviar Email para o criador solicitação
                    try {
                            let objetoEmal = {
                                destinatario:dados.emailsolicitante,
                                assunto:`Solicitação de Testes [${dados.idsolicitacao}] enviada para Produção`,
                                html:'Enviado para produção a atividade: '+dados.nomeatividade+'<br><br>Obs: '+this.movimento.descricao
                            }
                            SolicitacaoServico.enviarEmail(objetoEmal)
                    } catch (error) {
                        console.log(error)   
                    }

                    this.criarAtividadeNova(dados)
                }

             
     

            },
            setExibirModalConfiguracoes(item){
                this.exibirModalConfiguracoes = !this.exibirModalConfiguracoes
                this.atividadeselecionada = item

                if(this.exibirModalConfiguracoes && this.atividadeselecionada){
                    
                    //Analise de Possível Bug
                    if(this.atividadeselecionada.tipo == 1){
                        this.textoBotaoAtender = 'Registrar Atividade de Bug'
                        this.textoBotaoNegar = 'Negar Atendimento'
                    }

                    //Solicitação a Diretoria
                    if(this.atividadeselecionada.tipo == 2){
                        this.textoBotaoAtender = 'Aceitar e Gerar Atividade'
                        this.textoBotaoNegar = 'Negar'
                    }

                    //Solicitação de Nova Implantação
                    if(this.atividadeselecionada.tipo == 3){
                        this.textoBotaoAtender = 'Atender e Gerar Atividade'
                        this.textoBotaoNegar = 'Negar'
                    }

                    //Solicitação de Configuração apos a liberação do ambiente
                    if(this.atividadeselecionada.tipo == 4){
                        this.textoBotaoAtender = 'Atender e Gerar Atividade'
                        this.textoBotaoNegar = 'Negar'
                    }

                    //Solicitação de realização de testes
                    if(this.atividadeselecionada.tipo == 5){
                        this.textoBotaoAtender = 'Atender e Gerar Atividade'
                        this.textoBotaoNegar = 'Negar'
                    }

                    //Solicitação de Meet
                    if(this.atividadeselecionada.tipo == 6){
                        this.textoBotaoAtender = 'Aceitar e Participar'
                        this.textoBotaoNegar = 'Negar'
                        this.textoAuxiliar1 = 'Data da Reunião'
                        this.atividadeselecionada.dadoAuxiliar1 = this.atividadeselecionada.dt_estimativa
                        this.atividadeselecionada.dadoAuxiliar2 = this.atividadeselecionada.hora_agenda
                        this.textoAuxiliar2 = 'Hora da Reunião'
                    }


                    if(this.atividadeselecionada.tipo == 8){
                        this.textoBotaoAtender = 'Aceitar'
                        this.textoBotaoNegar = 'Negar'
                    }

                    if(this.atividadeselecionada.tipo == 9){
                        this.textoBotaoAtender = 'Aceitar'
                        this.textoBotaoNegar = 'Negar'
                    }

                    if(this.atividadeselecionada.tipo == 10){
                        this.textoBotaoAtender = 'Aceitar'
                        this.textoBotaoNegar = 'Negar'
                    }

                    if(this.atividadeselecionada.tipo == 11){
                        this.textoBotaoAtender = 'Aceitar'
                        this.textoBotaoNegar = 'Negar'
                    }

                    this.buscarMovimentos()
                }
            },
            async buscarUsuarios(){
                this.listadeusuarios = await UsuarioServico.buscar({tipo:1,ativo:true}).then(res => res.data)
                let indexUsuarioFiltrado = this.listadeusuarios.findIndex(x =>{ return x.id == localStorage.getItem('idusuario')})
                this.usuario = this.listadeusuarios[indexUsuarioFiltrado]
                if(!this.usuario){
                    this.usuario = this.listadeusuarios[0]
                }
            },
            async buscarClientes(){
                await Clientes.buscar({ativo:true}).then(res =>{
                    this.clientes = [{id:0,nome:'Sem Cliente'}].concat(res.data)
                })
            },
            async buscarTipoSolicitacoes(){
                await SolicitacaoServico.buscarTiposSolicitacoes() .then((res)=>{
                    this.tipos = res.data
                    this.tiposFiltros = [{id:0,nome:'Todos'},...res.data]
                })
            },
            async buscarPrioridade(){
              
                // await Prioridades.buscarPrioridade() .then((res)=>{
                let parametros = {ativo:true,solicitacoes:true}
              
                await SolicitacaoServico.buscarProjetos(parametros) .then((res)=>{
              
                    const {data} = res;
                    data.forEach((e) => {
                        const prioridade = { id: e.id, titulo: e.titulo }
                        this.selectPrioridade = [...this.selectPrioridade, prioridade]
                    })

              

                    this.selectPrioridade.sort(function (a, b) {
                        if (a.titulo > b.titulo) {
                            return 1;
                        }
                        if (a.titulo < b.titulo) {
                            return -1;
                        }
                        return 0;
                    });

                    
                    
                    this.filtroProjeto = this.selectPrioridade

                    const filter = data.filter((prioridade) => prioridade.status !== false);
                    
                    this.prioridades = filter;
                    
                    this.listadeprojetosFiltro = filter;
                    

                })
                .catch((error)=>{
                    
                    alert(error)
                })
            },
            novaAtividade() {
                this.atividadeCriar={ id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0 }
                this.exibirModalCriarAtividade = true;
                document.getElementById('campo-titulo').focus();
            },
            limpar(){
                this.dt_concluida_inicial = null
                this.dt_concluida_final = null
                this.dt_estimativa_inicial = null
                this.dt_estimativa_final = null
                this.idprojeto = 1
                this.usuario = 1
                this.atribuida = 1
                this.concluido = 1
                this.texto = null
                this.filtroRapidoSelecionado = {}
            },
            async listaSolicitacoesProjetos(){
                let parametros = {}
                this.atividadesProjetos = []
                parametros.idprojeto = this.idprojeto
                parametros.atribuida = this.atribuida
                parametros.concluido = this.concluido
                parametros.idusuario = this.idusuario
                parametros.idusuariosolicitante = this.idusuariosolicitante
                parametros.dt_concluida_inicial = this.dt_concluida_inicial
                parametros.dt_concluida_final = this.dt_concluida_final
                parametros.dt_estimativa_inicial = this.dt_estimativa_inicial
                parametros.dt_estimativa_final = this.dt_estimativa_final
                parametros.testado = this.atividades_testadas
                parametros.producao = this.atividades_producao
                parametros.texto = this.texto 
                parametros.id = this.idsolicitacaofiltro 
                parametros.tipo = this.tipofiltro
                
                if(this.statussolicitacaoselecionado == 1){parametros.fl_aceite = true }
                if(this.statussolicitacaoselecionado == 2){parametros.fl_negada = true }
                if(this.statussolicitacaoselecionado == 3){parametros.fl_pendente = true }


                this.nome_relatorio = dayjs().format('YYMMDDHHmmss')

                let projetofiltro = this.listaProjetos.find( x => { if ( x.id == this.idprojeto){ return x } })
                if(projetofiltro && projetofiltro.titulo){
                    this.nome_relatorio += "_"+ projetofiltro.titulo
                }

                let usuariofiltro = this.listaUsuarios.find( x => { if ( x.id == this.idusuario){ return x } })
                if(usuariofiltro && usuariofiltro.nomeusuario){
                    this.nome_relatorio += "_usu_"+ usuariofiltro.nomeusuario
                }

                if(parametros.dt_concluida_inicial){
                    this.nome_relatorio += '_dt_conc_ini_'+dayjs(parametros.dt_concluida_inicial).format('DD-MM-YYYY')
                }
                if(parametros.dt_concluida_final){
                    this.nome_relatorio += '_dt_conc_fim_'+dayjs(parametros.dt_concluida_final).format('DD-MM-YYYY')
                }
                
                if(parametros.dt_estimativa_inicial){
                    this.nome_relatorio += '_dt_est_ini_'+dayjs(parametros.dt_estimativa_inicial).format('DD-MM-YYYY')
                }
                if(parametros.dt_estimativa_final){
                    this.nome_relatorio += '_dt_est_fim_'+dayjs(parametros.dt_estimativa_final).format('DD-MM-YYYY')
                }

                if(parametros.concluido == 2){
                    this.nome_relatorio += '_concluida'
                }
                if(parametros.concluido == 3){
                    this.nome_relatorio += '_NAO_concluida'
                }


                this.nome_relatorio += '.csv'

                this.atividadesProjetos = await SolicitacaoServico.buscarSolicitacoesProjetos(parametros).then(res => res.data)
            },
            async listarProjetos(){
                let parametros = {ativo:true,solicitacoes:true}
                
                let listaproj = await SolicitacaoServico.buscarProjetos(parametros).then(res => res.data)
                
                for(let i=0, len=listaproj.length; i < len; i++){
                    this.listaProjetos.push(listaproj[i])
                }
                if(this.$route.params && this.$route.params.idprojeto){
                    this.idprojeto = this.$route.params.idprojeto
                }
            },
            async listarUsuarios(){
                let parametros = {ativo:true}
                let listausu = await usuarios.buscar(parametros).then(res => res.data)

                for(let i=0, len=listausu.length; i < len; i++){
                    this.listaUsuarios.push(listausu[i])
                }

                this.listaUsuariosSolicitantes = [...this.listaUsuarios]
               
               
                
            }
            
    },

    
    async mounted(){

        let iduser = parseInt(localStorage.getItem('idusuario'))
        this.idusuariologado = parseInt(localStorage.getItem('idusuario'))
        this.idusuario = iduser
        // this.dt_concluida_inicial = dayjs().format('YYYY-MM-DD')
        // this.dt_concluida_final = dayjs().format('YYYY-MM-DD')
        
        await this.buscarClientes()
        await this.buscarPrioridade()
        
        await this.buscarTipoSolicitacoes()
        
        await this.buscarUsuarios()
        
        await this.listarUsuarios()
        
        await this.listarProjetos()
        
        // await this.listaSolicitacoesProjetos()
        await this.listaSolicitacoesProjetos()
        

    }
    
}
</script>

<style>

/* .v-dialog {
    position: absolute;
    bottom: 0; 
    right: 0;
} */

    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

</style>